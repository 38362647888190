"use client";
import { useAuth } from "@clerk/nextjs";
import { AddCircleRounded } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { BottomNavigation, BottomNavigationAction, Fab, SvgIcon, Tooltip } from "@mui/material";
import Paper from "@mui/material/Paper";
import Link from "next/link";
import { useRouter, useSelectedLayoutSegments } from "next/navigation";
import { useEffect, useState } from "react";
import useMenuItems from "./CustomHooks/useMenuitems";
import AddEntity from "./Dialog/AddDrawer/AddEntity";
import CustomDrawer from "./Dialog/CustomDrawer";
import { tan } from "./Theme";

interface MenuProps {
  isBreeder?: boolean;
  hasBreederPage?: boolean;
}

const BottomNav = ({ isBreeder = false, hasBreederPage = false }: MenuProps) => {
  const [value, setValue] = useState<string | boolean>(false);
  const [open, setOpen] = useState(false);
  const router = useRouter();

  const segments = useSelectedLayoutSegments();

  const menuItems = useMenuItems(isBreeder, hasBreederPage);

  const insert = (arr, index, newItem) => [
    // part of the array before the specified index
    ...arr.slice(0, index),
    // inserted item
    newItem,
    // part of the array after the specified index
    ...arr.slice(index),
  ];
  const updatedMenuitems = insert(menuItems, 2, { label: "", href: "", icon: AddCircleRounded });

  useEffect(() => {
    setValue(() => {
      switch (segments[0]) {
        case "me":
          if (!segments[1]) {
            return false; // Should this be false instead? @Anne Skjaeveland (If a user is on the profile, this will put the tab under "Oppdrett", setting to false will remove the tab underline)
          } else {
            switch (segments[1]) {
              case "messages":
                return false;
              case "(protected)":
                return "/" + segments[0] + "/" + segments[2];
              default:
                return "/" + segments[0] + "/" + segments[1];
            }
          }
        case "admin":
          return false;
        case "search":
          return "/search?type=Breeder";
        default:
          return false;
      }
    });
  }, [segments]);

  const { isSignedIn } = useAuth();
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <>
      <Paper
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          width: "100vw",
          height: "56px",
          paddingBottom: "4px",
          alignItems: "center",
          textAlign: "center",
          display: { xs: "flex", md: "none" },
          zIndex: 1100,
        }}
      >
        <Tooltip
          open={showTooltip}
          onOpen={() => setShowTooltip(true)}
          onClose={() => setShowTooltip(false)}
          arrow
          title="Du kan kun legge til når du er logget inn"
        >
          <Fab
            color="primary"
            aria-label="add"
            sx={{ position: "absolute", left: "50%", transform: "translate(-50%, -10%)" }}
            onClick={() => (isSignedIn ? setOpen(true) : setShowTooltip(true))}
          >
            <AddIcon fontSize="large" />
          </Fab>
        </Tooltip>
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          sx={{ width: "100%", height: "100%" }}
        >
          {updatedMenuitems.map(({ label, href, options, icon }, index) => {
            return (
              <BottomNavigationAction
                key={href}
                label={label}
                href={href}
                sx={{
                  color: tan[300],
                  height: "100%",
                  textWrap: "nowrap",
                  minWidth: "60px",
                  ".Mui-selected": {
                    fontSize: "12px",
                  },
                }}
                value={href}
                color={value === href ? "primary" : "secondary"}
                LinkComponent={Link}
                icon={
                  <SvgIcon
                    fontSize="small"
                    color={value === href ? "primary" : "secondary"}
                    sx={{
                      ".dogface_svg__color": { fill: href === value ? tan[300] : "white" },
                    }}
                    inheritViewBox
                    component={icon}
                  />
                }
              />
            );
          })}
        </BottomNavigation>
      </Paper>
      <CustomDrawer sx={{ zIndex: 1000 }} open={open} setOpen={setOpen} sideTitle="Legg til">
        <AddEntity setOpen={setOpen} isBreeder={isBreeder} />
      </CustomDrawer>
    </>
  );
};

export default BottomNav;
