"use client";
import { useUser } from "@clerk/nextjs";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  Autocomplete,
  Box,
  Button,
  ButtonBase,
  Checkbox,
  Container,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { FormEvent, useEffect, useState } from "react";
import { CiImageOn } from "react-icons/ci";
import useLogCategories from "../../CustomHooks/useLogCategories";
import {
  fetchCreateMemberUploadAssetTokenEndpoint,
  fetchRegisterDogTrainingEndpoint,
  fetchRegisterLitterTrainingEndpoint,
  useGetPrivateBreederLittersDetailsEndpoint,
  useGetPrivateMemberBreederEndpoint,
  useGetPrivateMemberDogsEndpoint,
} from "../../generated/happydogsComponents";
import {
  DogPrivateDetailsView,
  LitterPrivateDetailsView,
  PuppyView,
  RegisterDogTrainingCommand,
  RegisterLitterTrainingCommand,
} from "../../generated/happydogsSchemas";
import { useObjectUrl } from "../../handleObject";
import Uploader from "../../ImageUpload";
import { tan } from "../../Theme";
import { HandleAsyncFunctionWithToast } from "../../Toasts";
import { isOfType } from "../../utils";

const icon = <CheckBoxOutlineBlankIcon color="secondary" />;
const checkedIcon = <CheckBoxIcon color="secondary" />;

const GenericAddNote = ({ setOpen }) => {
  const { user } = useUser();

  const memberId = user?.publicMetadata?.member_id as string;
  const [newNote, setNewNote] = useState<Partial<RegisterLitterTrainingCommand>>({ registeredAt: dayjs().toISOString() });
  const { data: dogs, refetch } = useGetPrivateMemberDogsEndpoint({ pathParams: { memberId } });
  const { data: breeder } = useGetPrivateMemberBreederEndpoint({ pathParams: { memberId } });
  const { data: litter, refetch: refetchLitters } = useGetPrivateBreederLittersDetailsEndpoint({
    pathParams: { breederId: breeder?.id ?? "" },
  });
  const [selectedPuppies, setSelectedPuppies] = useState<PuppyView[]>([]);

  const [selectedDog, setSelectedDog] = useState<DogPrivateDetailsView | LitterPrivateDetailsView>();
  const [showUploader, setShowUploader] = useState(false);
  const [picture, setPicture] = useState<File>();
  const src = useObjectUrl(picture);

  useEffect(() => {
    if (dogs) {
      setSelectedDog(dogs[0]);
    } else if (litter) {
      setSelectedDog(litter[0]);
    }
  }, [dogs]);

  const categories = useLogCategories([selectedDog as LitterPrivateDetailsView]);

  const handleChangeCategory = (value: string[] | string) => {
    if (!newNote.title) {
      setNewNote({ ...newNote, title: typeof value === "string" ? value : value[0] });
    }
    setNewNote((old) => ({
      ...old,
      categories:
        // On autofill we get a stringified value.
        typeof value === "string" ? value.split(",") : value,
    }));
  };

  const PostNewNote = HandleAsyncFunctionWithToast(
    async (command: Omit<RegisterDogTrainingCommand, "dogId" | "memberId" | "images">, e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.currentTarget.reportValidity();
      setOpen(false);
      if (!selectedDog) return;
      var file;
      if (picture) {
        file = await fetchCreateMemberUploadAssetTokenEndpoint({
          body: {
            memberId,
            mimeType: picture.type,
          },
        });
        await fetch(file.url, {
          method: "PUT",
          body: picture,
          headers: file.headers,
        });
      }
      if (selectedDog && isOfType<DogPrivateDetailsView>(selectedDog, "dogSlug")) {
        await fetchRegisterDogTrainingEndpoint({
          body: {
            dogId: selectedDog.id,
            memberId,
            images: picture
              ? [
                  {
                    unsafeFilename: picture.name,
                    url: file.url,
                    mimeType: picture.type,
                    assetId: file.assetId,
                  },
                ]
              : [],
            ...command,
          },
        });
        await refetch();
      } else {
        await fetchRegisterLitterTrainingEndpoint({
          body: {
            puppyIds: selectedPuppies.map((puppy) => puppy.puppyId),
            litterId: selectedDog.id,
            images: picture
              ? [
                  {
                    unsafeFilename: picture.name,
                    url: file.url,
                    mimeType: picture.type,
                    assetId: file.assetId,
                  },
                ]
              : [],
            memberId,
            ...command,
          },
        });
        await refetchLitters();
      }
      setNewNote({});
    },
  );

  return (
    <Container
      maxWidth="md"
      sx={{ display: "flex", flexDirection: "column", gap: { md: "30px", xs: "20px" } }}
      component={"form"}
      onSubmit={(e) => PostNewNote(newNote as Omit<RegisterDogTrainingCommand, "dogId" | "images" | "memberId">, e)}
    >
      <Typography variant="h2">Legg til notat</Typography>
      <Box
        sx={{
          display: "flex",
          gap: { md: "30px", xs: "10px" },
        }}
      >
        <Box display={"flex"} flexDirection={"column"} minWidth={"50%"} maxWidth={"100%"} width={"100%"} gap={{ md: "30px", xs: "20px" }}>
          <TextField
            required
            color="secondary"
            name="title"
            label="Tittel"
            value={newNote.title ? newNote.title : ""}
            onChange={(e) => setNewNote({ ...newNote, title: e.target.value })}
          />
          <FormControl>
            <InputLabel color="secondary">Kategori *</InputLabel>
            <Select
              required
              multiple
              value={newNote?.categories ? newNote.categories : []}
              onChange={(e) => {
                handleChangeCategory(e.target.value);
              }}
              input={<OutlinedInput color="secondary" label="Kategori " />}
              renderValue={(selected) => selected.join(", ")}
              sx={{ backgroundColor: "#fff", borderRadius: "10px", color: tan[300], border: "1px", maxWidth: "100%", textAlign: "left" }}
              color="secondary"
            >
              {categories.map((category) => (
                <MenuItem key={category} value={category}>
                  <Checkbox icon={icon} color="secondary" checked={newNote.categories?.includes(category) ?? false} />
                  <ListItemText primary={category} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            required
            name="content"
            multiline
            color="secondary"
            rows={9}
            label={"Beskrivelse"}
            onChange={(e) => setNewNote({ ...newNote, content: e.target.value })}
          />
        </Box>
        <Box
          display={"flex"}
          flexDirection={"column"}
          minWidth={"50%"}
          maxWidth={"100%"}
          width={"100%"}
          gap={{ md: "30px", xs: "20px" }}
          textAlign={"left"}
        >
          {(dogs || litter) && (
            <>
              <FormControl>
                <Autocomplete
                  disableClearable
                  getOptionLabel={(option: DogPrivateDetailsView | LitterPrivateDetailsView) =>
                    isOfType<DogPrivateDetailsView>(option, "dogSlug") ? option.registryName : option.litterName
                  }
                  options={[dogs, litter].reduce(
                    (acc: Array<DogPrivateDetailsView | LitterPrivateDetailsView>, arr) => (arr ? acc?.concat(arr) : acc),
                    [],
                  )}
                  value={selectedDog}
                  color="secondary"
                  renderInput={(params) => <TextField required label={litter ? "Hund/Kull" : "Hund"} {...params} />}
                  style={{ backgroundColor: "#fff", borderRadius: "10px" }}
                  onChange={(_, newValue) => {
                    setSelectedDog(newValue as DogPrivateDetailsView);
                  }}
                />
              </FormControl>
              {selectedDog && isOfType<LitterPrivateDetailsView>(selectedDog, "litterName") && (
                <FormControl>
                  <Autocomplete
                    renderTags={(options) => <Typography>{options.map((option) => option.name).join(", ")}</Typography>}
                    multiple
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                        {option.name}
                      </li>
                    )}
                    disableCloseOnSelect
                    disableClearable
                    getOptionLabel={(option) => option.name}
                    options={selectedDog?.puppies ?? []}
                    value={selectedPuppies}
                    color="secondary"
                    renderInput={(params) => <TextField label="Valper" {...params} maxRows={2} />}
                    style={{ backgroundColor: "#fff", borderRadius: "10px" }}
                    onChange={(_, newValue) => setSelectedPuppies(newValue)}
                  />
                </FormControl>
              )}
            </>
          )}
          <DatePicker
            label="Dato"
            format="DD.MM.YYYY"
            value={dayjs(newNote.registeredAt) ?? dayjs()}
            onChange={(newValue: Dayjs | null) => {
              setNewNote({
                ...newNote,
                registeredAt: newValue?.toISOString(),
              });
            }}
          />
          <ButtonBase
            sx={{
              height: "100%",
              border: `2px dotted ${tan[50]}`,
              maxWidth: "100%",
              width: picture ? "fit-content" : "100%",
              borderRadius: "10px",
            }}
            onClick={() => setShowUploader(true)}
          >
            <Box position={"absolute"}>
              {!picture && <CiImageOn color={tan[300]} fontSize={"30px"} />}
              <Typography variant="subtitle2">{picture ? "Endre bilde" : "Last opp bilde"}</Typography>
            </Box>
            <Box height={"120px"} maxWidth={"100%"}>
              <img
                style={{ display: "flex", width: "100%", height: "120px", objectFit: "cover", borderRadius: "10px", opacity: 0.4 }}
                alt=""
                src={src}
              />
            </Box>
          </ButtonBase>
        </Box>
      </Box>
      <Uploader
        id="add-training-image"
        onUpload={(file: File, _ = false) => Promise.resolve(setPicture(file))}
        uploadImage={showUploader}
        handleClose={() => setShowUploader(false)}
        setProfilePicture={true}
        closeAfterUpload={true}
        aspectRatio={1.5}
      />
      <Box width={"100%"} display={"flex"} alignItems={"end"} justifyContent={"space-between"} gap={"10px"}>
        <Button variant="outlined" color="secondary" onClick={() => setOpen(false)} type="reset">
          Avbryt
        </Button>
        <Button variant="contained" type="submit">
          Lagre
        </Button>
      </Box>
    </Container>
  );
};

export default GenericAddNote;
