import { Box, ButtonBase, Container, Stack, SvgIcon, Typography } from "@mui/material";
import { useRouter } from "next/navigation";
import { Dispatch, SetStateAction, useState } from "react";
import { dogface, litterIcon, puppyLog, utvikling } from "../../../public/assets/icons";
import { tan } from "../../Theme";
import AddDog from "./AddDog";
import AddLitter from "./AddLitter";
import GenericAddNote from "./GenericAddNote";
import GenericAddWeight from "./GenericAddWeight";

const AddEntity = ({ setOpen, isBreeder }: { setOpen: Dispatch<SetStateAction<boolean>>; isBreeder: boolean }) => {
  const [activeTab, setActiveTab] = useState(0);

  const Tabs = [
    <>
      <AddButton title={"Hund"} handleClick={() => setActiveTab(2)} component={dogface} />
      {isBreeder && <AddButton title={"Kull"} handleClick={() => setActiveTab(3)} component={litterIcon} />}
      <AddButton title={"Loggnotat"} handleClick={() => setActiveTab(1)} component={puppyLog} />
      {isBreeder && <AddButton title={"Vektlogg"} handleClick={() => setActiveTab(4)} component={utvikling} />}
    </>,
    <GenericAddNote setOpen={setOpen} />,
    <AddDog setOpen={setOpen} />,
    <AddLitter setOpen={setOpen} />,
    <GenericAddWeight setOpen={setOpen} />,
  ];

  const router = useRouter();
  return (
    <Container maxWidth="lg">
      {activeTab === 0 && (
        <Typography padding={"0"} fontWeight={"bold"} variant="h2" textAlign={"left"} pb={"8px"}>
          Legg til
        </Typography>
      )}

      <Box pb={"60px"} display={"flex"} gap={"20px"}>
        {Tabs[activeTab]}
      </Box>
    </Container>
  );
};

export default AddEntity;

const AddButton = ({ title, handleClick, component }) => {
  return (
    <ButtonBase onClick={handleClick}>
      <Stack alignItems={"center"}>
        <Box mb="3px" padding={"10px"} border={`1px solid ${tan[300]}`} borderRadius={"10px"}>
          <SvgIcon
            color={"primary"}
            sx={{
              width: "40px",
              height: "40px",
              ".dogface_svg__color": { fill: tan[300] },
            }}
            inheritViewBox
            component={component}
          />
        </Box>
        <Typography variant="subtitle2"> {title}</Typography>
      </Stack>
    </ButtonBase>
  );
};
