"use client";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import "dayjs/locale/nb";
import { RichTextEditorProvider } from "mui-tiptap";
import dynamic from "next/dynamic";
import { SnackbarProvider } from "notistack";
import { Theme } from "../components/Theme";
import { WatchMemberId } from "../components/waiting";
import "../styles/globals.css";

const queryClient = new QueryClient();

const ClarityProvider = dynamic(() => import("./clarity"), { ssr: false });

export default function Providers({ children }: { children: React.ReactNode }) {
  const editor = useEditor({
    extensions: [StarterKit],
    content: "",
  });

  return (
    <Theme>
      <ClarityProvider>
        <RichTextEditorProvider editor={editor}>
          <SnackbarProvider maxSnack={2}>
            <QueryClientProvider client={queryClient}>
              <WatchMemberId />
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="nb-NO">
                <>{children}</>
              </LocalizationProvider>
            </QueryClientProvider>
          </SnackbarProvider>
        </RichTextEditorProvider>
      </ClarityProvider>
    </Theme>
  );
}
