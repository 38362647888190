import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from "@tanstack/react-query";

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const isOfType = <T>(varToBeChecked: any, propertyToCheckFor: keyof T): varToBeChecked is T =>
  (varToBeChecked as T)[propertyToCheckFor] !== undefined;

export const isArrayOf = <T>(arrayToBeChecked: any[], propertyToCheckFor: keyof T): arrayToBeChecked is T[] => {
  if (!arrayToBeChecked) return false;
  else {
    return (arrayToBeChecked as T)[0][propertyToCheckFor] !== undefined;
  }
};

export type RefetchType<TPageData = unknown> = (
  options?: RefetchOptions & RefetchQueryFilters<TPageData>,
) => Promise<QueryObserverResult<TPageData, {}>>;

export type FullRequired<T extends object> = Required<{
  [K in keyof T]: Exclude<T[K], undefined>;
}>;
