import { Dialog } from "@mui/material";
import Uppy, { BasePlugin } from "@uppy/core";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import ImageEditor from "@uppy/image-editor";
import "@uppy/image-editor/dist/style.css";
import { Dashboard } from "@uppy/react";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

export type OnUploadFunc = (file: File, setProfilePicture: boolean) => Promise<unknown> | unknown;

const createUppy = (id: string, setProfilePicture: boolean, onUpload: OnUploadFunc, aspectRatio?: number) => {
  // Adding to global `meta` will add it to every file.
  // Every Uppy instance needs a unique ID.
  return new Uppy({
    id,
    meta: { id },
    restrictions: {
      maxFileSize: 15 * 1024 * 1024,
      allowedFileTypes: [".jpg", ".jpeg", "gif", ".png"],
      maxNumberOfFiles: setProfilePicture ? 1 : null,
    },
  })
    .use(ImageEditor, {
      id: "ImageEditor",
      actions: undefined,
      cropperOptions: {
        croppedCanvasOptions: {},
        background: false,
        autoCrop: setProfilePicture,
        responsive: true,
        aspectRatio: aspectRatio ? aspectRatio : setProfilePicture ? 1 : 1.5,
      },
    })
    .use(CustomUploader, { id: "CustomUploader", onUpload, setProfilePicture });
};

class CustomUploader extends BasePlugin {
  onUpload: (file: File | Blob, setProfilePicture?: boolean) => Promise<void>;
  setProfilePicture: boolean;

  constructor(uppy, opts) {
    super(uppy, opts);
    this.uppy = uppy;
    this.id = opts.id;
    this.type = "uploader";
    this.upload = this.upload.bind(this);
    this.onUpload = opts.onUpload;
    this.setProfilePicture = opts.setProfilePicture;
  }

  upload = async (fileIDs) => {
    const files = fileIDs.map((id) => this.uppy.getFile(id));
    this.uppy.emit("upload-start", files);

    await Promise.allSettled(
      files.map((file) => {
        const { data } = file;
        this.onUpload(data, this.setProfilePicture)
          .then(() => {
            this.uppy.emit("upload-progress", file, {
              uploader: this,
              bytesUploaded: file.progress.bytesTotal,
              bytesTotal: file.progress.bytesTotal,
            });
            this.uppy.emit("upload-success", file, { status: 200 });
            return Promise.resolve(file);
          })
          .catch(() => {
            this.uppy.emit("upload-error", file, new Error("Unknown error, please try again"));
            return Promise.reject();
          });
      }),
    );
  };

  install() {
    this.uppy.addUploader(this.upload);
  }

  uninstall() {
    this.uppy.removeUploader(this.upload);
  }
}

interface UploaderProps {
  id: string;
  onUpload: OnUploadFunc;
  uploadImage: boolean;
  handleClose: () => void;
  setProfilePicture: boolean;
  closeAfterUpload?: boolean;
  aspectRatio?: number;
}

const Uploader = ({
  id,
  onUpload,
  uploadImage,
  handleClose,
  setProfilePicture = false,
  closeAfterUpload = false,
  aspectRatio,
}: UploaderProps) => {
  const [uppy] = useState(() => createUppy(id, setProfilePicture, onUpload, aspectRatio));

  const closeUploader = () => {
    uppy.cancelAll();
    handleClose();
  };

  useEffect(() => {
    uppy.on("dashboard:modal-closed", closeUploader);
  }, [closeUploader]); // Listen for changes to closeUploader and update state setter

  if (closeAfterUpload) {
    useEffect(() => {
      uppy.on("upload-success", closeUploader);
      return () => {
        uppy.off("upload-success", closeUploader);
      };
    }, []);
  }
  return (
    <>
      <Dialog open={uploadImage} onClose={closeUploader}>
        <Dashboard uppy={uppy} plugins={["ImageEditor"]} autoOpenFileEditor={setProfilePicture} doneButtonHandler={closeUploader} />
      </Dialog>
    </>
  );
};

Uploader.propTypes = {
  id: PropTypes.string,
};

export default Uploader;
