import { useUser } from "@clerk/nextjs";
import { Autocomplete, Box, Button, Container, Stack, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { Dispatch, FormEvent, SetStateAction, useState } from "react";
import {
  fetchRegisterLitterPuppyWeightEndpoint,
  useGetPrivateBreederLittersDetailsEndpoint,
  useGetPrivateMemberBreederEndpoint,
} from "../../generated/happydogsComponents";
import { DogPrivateDetailsView, LitterPrivateDetailsView, PuppyView, RecordedLogView } from "../../generated/happydogsSchemas";
import { HandleMultiplePromisesWithToast } from "../../Toasts";
import { isOfType } from "../../utils";

interface ModalProps {
  setOpen: Dispatch<SetStateAction<boolean>>;
  defaultIndex?: number;
}

const GenericAddWeight = ({ setOpen, defaultIndex = 0 }: ModalProps) => {
  const { user } = useUser();

  const memberId = user?.publicMetadata?.member_id as string;
  //const { data: dogs, refetch } = useGetPrivateMemberDogsEndpoint({ pathParams: { memberId } });
  const { data: breeder } = useGetPrivateMemberBreederEndpoint({ pathParams: { memberId } });
  const { data: litter, refetch: refetchLitters } = useGetPrivateBreederLittersDetailsEndpoint({
    pathParams: { breederId: breeder?.id ?? "" },
  });

  const [newWeight, setNewWeight] = useState<Partial<RecordedLogView>>({ value: "0", registeredAt: dayjs().toISOString() });

  const [selectedPuppy, setSelectedPuppy] = useState<PuppyView>();

  const [currentLitter, setCurrentLitter] = useState<number>(defaultIndex);

  const handleSubmit = HandleMultiplePromisesWithToast(
    function* (e: FormEvent<HTMLFormElement>) {
      e.preventDefault();
      e.currentTarget.reportValidity();

      if (!litter) return;
      for (const puppy of litter[currentLitter]?.puppies) {
        const weight = e.currentTarget[puppy.puppyId].value;
        if (weight) {
          yield fetchRegisterLitterPuppyWeightEndpoint({
            body: {
              litterId: litter[currentLitter].id,
              grams: Number(weight),
              registeredAt: newWeight.registeredAt || dayjs().toISOString(),
              puppyId: puppy.puppyId,
            },
          });
        }
      }
    },
    async () => {
      await refetchLitters();
      setOpen(false);
      setNewWeight({ value: "", registeredAt: dayjs().toISOString() });
    },
  );

  return (
    <Container
      maxWidth="md"
      sx={{ textAlign: "left", display: "flex", width: "100%", gap: "10px", flexDirection: "column" }}
      component={"form"}
      onSubmit={handleSubmit}
    >
      {" "}
      <Typography variant="h2" textAlign={"center"}>
        Legg til vekt
      </Typography>
      <Stack gap={{ md: "30px", xs: "20px" }} textAlign={"left"} width={"100%"}>
        <Box width={"100%"} display={"flex"} gap={"15px"} flexDirection={"column"}>
          {litter && (
            <>
              <Autocomplete
                fullWidth
                disableClearable
                getOptionLabel={(option: DogPrivateDetailsView | LitterPrivateDetailsView) =>
                  isOfType<DogPrivateDetailsView>(option, "dogSlug") ? option.registryName : option.litterName
                }
                options={litter}
                value={litter[currentLitter]}
                color="secondary"
                renderInput={(params) => <TextField required label="Kull" {...params} />}
                style={{ backgroundColor: "#fff", borderRadius: "10px" }}
                onChange={(_, newValue: LitterPrivateDetailsView) => setCurrentLitter(litter.indexOf(newValue))}
              />
            </>
          )}{" "}
          <DatePicker
            label="Dato"
            sx={{ width: "100%" }}
            minDate={dayjs(litter?.[currentLitter]?.term)}
            format="DD.MM.YYYY"
            maxDate={dayjs()}
            value={dayjs(newWeight.registeredAt)}
            onChange={(date: Dayjs) => setNewWeight((old) => ({ ...old, registeredAt: date.toISOString() }))}
          />
        </Box>
        {litter?.[currentLitter]?.puppies.map((puppy) => (
          <Box key={puppy.puppyId} width={"100%"} display={"flex"} gap="15px" alignItems={"center"}>
            <Typography variant="subtitle2" width={"25%"}>
              {puppy.name}
            </Typography>
            <TextField fullWidth label="Vekt i gram" type="number" name={puppy.puppyId} />
          </Box>
        ))}
      </Stack>
      <Box width={"100%"} display={"flex"} alignItems={"end"} justifyContent={"space-between"} gap={"10px"} mt="10px">
        <Button variant="outlined" color="secondary" onClick={() => setOpen(false)} type="reset">
          Avbryt
        </Button>
        <Button variant="contained" type="submit">
          Lagre
        </Button>
      </Box>
    </Container>
  );
};

export default GenericAddWeight;
