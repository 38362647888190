import { useMemo } from "react";
import { DogPrivateDetailsView, LitterPrivateDetailsView } from "../generated/happydogsSchemas";

const useLogCategories = (dogs: DogPrivateDetailsView[] | LitterPrivateDetailsView[] | undefined) => {
  return useMemo(() => {
    const options = [
      "Miljøtrening",
      "Sosialisering",
      "Stell",
      "Ernæring",
      "Helse",
      "Trening",
      "Konkurranse",
      "Temperatur",
      "Utvikling",
      "Annet",
    ];
    if (!dogs) return options;
    if (dogs.some((dog) => dog?.isMale) || dogs.length < 0) {
      options.push("Parring");
    }
    if (dogs.some((dog) => !dog?.isMale) || dogs.length < 0) {
      options.push(`Løpetid/Parring/ drektighet`);
      options.push("Fødsel");
    }

    return options;
  }, [dogs]);
};

export default useLogCategories;
